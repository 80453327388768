import React, {useLayoutEffect, useState, useEffect} from "react";
import ReactGA from 'react-ga';

// Importing the Bootstrap CSS
import 'swiper/css/pagination';

import 'bootstrap/dist/css/bootstrap-grid.css';

import './assets/sass/style.scss';

import Router from "./router/Router";
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import dsnEffect, {fade, moveSection, textAnimation} from "./animation/DsnEffect";
import Layout from "./layout/Layout";

import song from "./assets/audio/background.mp3";
import MusicIcon from "./assets/img/music.svg";

import useSound from 'use-sound';


const TRACKING_ID = "G-VERT7MR821";
ReactGA.initialize(TRACKING_ID)

function App() {

    const [isPlaying, setIsPlaying] = useState(false);
    const [play, { stop }] = useSound(song);

    useEffect (() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    function playSong() {
        setIsPlaying(true);
        play();
      }
    
      function stopSong() {
        setIsPlaying(false);
        stop();
      }



    useLayoutEffect(() => {

        gsap.registerPlugin(ScrollTrigger);
        dsnEffect.registerEffect(moveSection, fade, textAnimation)


        gsap.config({
            nullTargetWarn: false
        });
        gsap.defaults({
            ease: "none",
            duration: 1,
            overwrite: "auto",
        });
    }, []);



    return (

            <Layout className="v-dark background-main" tag="main">
                <Router />

                <img className="pulsating-circle" 
                    onClick={isPlaying ? stopSong : playSong}
                    src={MusicIcon} 
                    alt="Your SVG" 
                    style={{
                        position: "fixed",
                        bottom: "30px",
                        right: "30px",
                        zIndex: "100",
                        height: "40px",
                        width: "40px",
                        padding: "0px",
                        mixBlendMode: "difference",
                }} />
            </Layout>
    );
}

export default App;
