import React from 'react';
import {dsnCN} from "../../../hooks/helper";

function MenuContent({className}) {
    const socialData = [
        {link: "#0", name: "Facebook."},
        {link: "#0", name: "Linkedin."},
        {link: "#0", name: "Twitter."}
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                {/* <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Address</h5>
                    <p>
                        Brivibas street 1<br/> Riga, Latvia, LV-1010
                    </p>

                </div> */}
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contact</h5>
                    <p className="links over-hidden mb-1">
                        <a className="link-hover" href="tel:+371 2999 1997" data-hover-text="+371 2999 1997">
                            +7 966 197-67-99
                        </a>
                    </p>
                    <p className="links over-hidden">
                        <a className="link-hover" href="mailto:info@manifest-customs"
                           data-hover-text="info@manifest-customs">info@manifest-customs.com</a>
                    </p>
                </div>
            </div>
            {/* <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Follow us</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div> */}
        </div>
    );
}

export default MenuContent;